import React from 'react'

import Icon from '../data/icon.png';
const Entete = () => {
    return (
        <div className="flex justify-between gap-3">
            <div className="flex flex-col gap-3">
                <h1>Ecoles privées El Izdihar N°1</h1>
                <h1>Discipline-Diligence-Réussite</h1>
                <h2>Tel: 46934541-46507852-36507852</h2>
            </div>
            <img className="mx-auto h-28 w-auto" src={Icon} alt="Your Company" />
            <div className="flex flex-col text-right gap-3">
                <h1>مدارس الإزدهار رقم واحد الحرة</h1>
                <h1> انضباط - اجتهاد - نجاح </h1>
                <h2> 46934541-46507852-36507852: الهاتف</h2>
            </div>
        </div>
    )
}

export default Entete